import axios from 'axios';
import {getEnv} from '@/config/environment';

const contentStudioInstance = axios.create({
  baseURL: getEnv().VITE_CONTENT_STUDIO_API_HOST || 'http://localhost:8001',
});

// TODO: Add interceptors (Monday: 8828191461)

export default contentStudioInstance;
