import React, {useState} from 'react';
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  Button,
  Headline,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

interface AspectRatio {
  ratio: string;
  label: string;
}

const aspectRatios: AspectRatio[] = [
  {ratio: '16:9', label: '16:9 (Landscape)'},
  {ratio: '1:1', label: '1:1 (Square)'},
  {ratio: '9:16', label: '9:16 (Portrait)'},
  {ratio: '4:5', label: '4:5 (Instagram)'},
];

interface ReformattingConfigurationProps {
  onReformat?: (selectedAspectRatios: string[]) => void;
}

function ReformattingConfiguration({
  onReformat,
}: ReformattingConfigurationProps) {
  const [selectedAspectRatios, setSelectedAspectRatios] = useState<string[]>([
    '16:9',
  ]);

  const handleAspectRatioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {value} = event.target;
    setSelectedAspectRatios((prev) => {
      if (prev.includes(value)) {
        // Remove if already selected
        return prev.filter((item) => item !== value);
      }
      // Add if not selected
      return [...prev, value];
    });
  };

  const handleReformat = () => {
    if (onReformat && selectedAspectRatios.length > 0) {
      onReformat(selectedAspectRatios);
    }
  };

  return (
    <Box
      sx={{
        padding: '24px',
        borderRadius: '8px',
        backgroundColor: designSystemToken('semantic.bg.secondary'),
        boxShadow: `0px 4px 10px ${designSystemToken(
          'semantic.shadow.default'
        )}`,
      }}
    >
      <Stack spacing={3}>
        <Headline size="sm">{translate('Reformatting Configuration')}</Headline>

        <FormControl component="fieldset">
          <FormLabel component="legend">
            {translate('Choose Aspect Ratios')}
          </FormLabel>
          <FormGroup>
            {aspectRatios.map((aspectRatio) => (
              <FormControlLabel
                key={aspectRatio.ratio}
                control={
                  <Checkbox
                    checked={selectedAspectRatios.includes(aspectRatio.ratio)}
                    onChange={handleAspectRatioChange}
                    value={aspectRatio.ratio}
                  />
                }
                label={aspectRatio.label}
              />
            ))}
          </FormGroup>
        </FormControl>

        <Box display="flex" justifyContent="flex-end">
          <Button
            appearance="brand"
            mode="filled"
            size="medium"
            onClick={handleReformat}
            disabled={selectedAspectRatios.length === 0}
          >
            {translate('Reformat')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default ReformattingConfiguration;
