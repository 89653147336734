import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Stack} from '@mui/material';
import translate from '@/utils/translate';
import FeatureCard from './components/feature-card';
import Header from './components/header';

function ContentStudio() {
  const navigate = useNavigate();

  const features = [
    {
      title: 'Scale to any platform',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      path: '/content-studio/reformatting',
    },
    {
      title: 'Dub to any language',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      path: '/content-studio/dubbing',
    },
    {
      title: 'Rephrase ad transcript',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      path: '/content-studio/rephrasing',
    },
  ];

  return (
    <Stack
      direction="column"
      width="100%"
      alignItems="center"
      minHeight="100vh"
    >
      <Header />
      <Stack
        direction="row"
        width="100%"
        flex={1}
        justifyContent="center"
        spacing="16px"
      >
        {features.map((feature) => (
          <FeatureCard
            key={feature.title}
            title={translate(feature.title)}
            description={translate(feature.description)}
            onClick={() => navigate(feature.path)}
          />
        ))}
      </Stack>
    </Stack>
  );
}

export default ContentStudio;
