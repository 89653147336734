import React from 'react';
import {Stack, Paper} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';
import Header from '@/views/content-studio/components/header';

interface FeatureScreenProps {
  LeftPanel: React.ComponentType;
  RightPanel: React.ComponentType;
  leftPanelWidth?: string | number;
  rightPanelWidth?: string | number;
}

function FeaturePage({
  LeftPanel,
  RightPanel,
  leftPanelWidth = '33%',
  rightPanelWidth = '67%',
}: FeatureScreenProps) {
  return (
    <Stack
      direction="column"
      width="100%"
      alignItems="center"
      minHeight="100vh"
      spacing={2}
    >
      <Header />

      {/* Main content with horizontal split */}
      <Stack
        direction="row"
        width="100%"
        flex={1}
        spacing={2}
        sx={{minHeight: 'calc(100vh - 80px)'}}
      >
        {/* Left panel */}
        <Paper
          elevation={1}
          sx={{
            width: leftPanelWidth,
            bgcolor: designSystemToken('semantic.bg.secondary'),
            overflow: 'auto',
          }}
        >
          <LeftPanel />
        </Paper>

        {/* Right panel */}
        <Paper
          elevation={1}
          sx={{
            width: rightPanelWidth,
            bgcolor: designSystemToken('semantic.bg.secondary'),
            overflow: 'auto',
          }}
        >
          <RightPanel />
        </Paper>
      </Stack>
    </Stack>
  );
}

export default FeaturePage;
