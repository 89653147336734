import React, {useCallback, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import axiosContentStudio from '@/utils/axiosContentStudio';
import FeaturePage from '../feature-page/FeaturePage';
import DubbingConfiguration from './DubbingConfiguration';
import DubbingResults from './DubbingResults';

function ConfigurationPanel(setProjectId: (projectId: string) => void) {
  const handleDubAssets = async () => {
    const payload = {
      asset_ids: [uuidv4()],
      project_name: 'placeholder',
      languages: ['es'],
      enable_lip_sync: true,
      num_of_speakers: 1,
    };

    try {
      const response = await axiosContentStudio.post(
        '/api/v1/projects/dubbing',
        payload
      );

      // Extract project_id from response
      if (response.data && response.data.project_id) {
        setProjectId(response.data.project_id);
      }
    } catch (error) {
      console.error('Error during dubbing process:', error);
    }
  };

  return <DubbingConfiguration onDubAssets={handleDubAssets} />;
}

function DubbingPage() {
  const [projectId, setProjectId] = useState<string | null>(null);

  return (
    <FeaturePage
      LeftPanel={() => ConfigurationPanel(setProjectId)}
      RightPanel={() => DubbingResults({projectId})}
    />
  );
}

export default DubbingPage;
