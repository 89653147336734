import React, {ChangeEvent, useCallback} from 'react';
import {
  AutocompleteChangeDetails,
  FilterOptionsState,
} from '@mui/base/useAutocomplete/useAutocomplete';
import {
  Autocomplete as AutocompleteMui,
  AutocompleteRenderOptionState,
  Box,
  styled,
  SxProps,
  TextField,
  Theme,
} from '@mui/material';
import {
  AutocompleteRenderGetTagProps,
  AutocompleteRenderGroupParams,
} from '@mui/material/Autocomplete/Autocomplete';
import {
  Body,
  Checkbox,
  designSystemToken,
  Icon,
} from '@lightricks/react-design-system';
import Chip from '@/components/chip';
import styles from './AutocompleteInput.module.scss';

const checkboxCheckedSrc = '/assets/svg/checkbox-checked.svg';
const checkboxUncheckedSrc = '/assets/svg/checkbox-unchecked.svg';

export type Option = {
  id: string;
  label: string;
  groupTitle?: string;
  value?: any;
  disabled?: boolean;
};

export type AutocompleteInputProps = {
  testID?: string;
  multiple?: boolean;
  loading?: boolean;
  multipleTextMode?: 'label' | 'chips';
  clearIcon?: React.ReactNode | boolean;
  options: Option[];
  selectedOptions: Option[] | Option | null;
  disableCloseOnSelect?: boolean;
  onChange: (
    event: React.SyntheticEvent,
    value: any,
    reason: string,
    details?: AutocompleteChangeDetails<any>
  ) => void;
  onInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  groupBy?: (option: Option) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
  sx?: SxProps<Theme>;
  renderOption?: (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  renderOptionLabel?: (option: Option) => React.ReactNode;
  inputValue?: string;
  disabled?: boolean;
  minWidth?: number;
  minHeight?: number;
  filterOptions?: (
    options: Option[],
    state: FilterOptionsState<Option>
  ) => Option[];
  hideInputCursor?: boolean;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onBlur?: (
    event: React.FocusEvent,
    inputValue: string | number | readonly string[] | undefined
  ) => void;
  isOptionEqualToValue?: (option: Option, value: Option) => boolean;
  forcePopupIcon?: boolean;
  selectedOptionVariant?: 'outlined' | 'filled';
  borderWidth?: number;
};

const shouldNotForwardProps = ['borderWidth'];

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop: string) => !shouldNotForwardProps.includes(prop),
})<{borderWidth: number}>`
  .MuiInputBase-root {
    padding-right: ${({className}) =>
      className === 'smallPadding' ? `35px` : 'auto'} !important;

    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
      border: ${({borderWidth}) => borderWidth}px solid
        ${({disabled}) =>
          `${designSystemToken('semantic.bg.neutral-secondary')}${
            disabled ? ' !important' : ''
          }`};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border: ${({borderWidth}) => borderWidth}px solid
        ${({disabled}) =>
          disabled
            ? designSystemToken('semantic.bg.neutral-secondary')
            : 'inherit'} !important;
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
      padding: 0 4px 0 5px;
    }

    &.Mui-focused,
    :hover {
      .MuiOutlinedInput-notchedOutline {
        border: ${({borderWidth}) => borderWidth}px solid
          ${() => designSystemToken('semantic.fg.neutral')};
      }
    }
  }
`;

function AutocompleteInput(props: AutocompleteInputProps) {
  const {
    testID = 'autocomplete-input',
    multiple = false,
    loading = false,
    multipleTextMode = 'chips',
    clearIcon = (
      <Icon
        size="medium"
        appearance="neutral"
        name="Actions-Close-Small"
        color={designSystemToken('semantic.fg.neutral')}
      />
    ),
    options,
    selectedOptions,
    disableCloseOnSelect = true,
    onChange,
    onInputChange,
    placeholder,
    groupBy,
    renderGroup,
    sx,
    renderOption,
    renderOptionLabel,
    inputValue,
    disabled,
    minWidth,
    minHeight = 56,
    filterOptions,
    hideInputCursor,
    onKeyDown,
    onBlur,
    isOptionEqualToValue = (option, _value) => option.id === _value?.id,
    forcePopupIcon = !!options.length,
    selectedOptionVariant = 'filled',
    borderWidth = 1,
  } = props;

  const renderPlaceholder = useCallback(() => {
    if (multiple && (selectedOptions as Option[]).length > 0) {
      return undefined;
    }

    return placeholder;
  }, [multiple, placeholder, selectedOptions]);

  const handleRenderTags = (
    value: Option[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    if (multipleTextMode === 'label') {
      return value.map((option: {id: string; label: string}, index: number) => {
        const isLast = index === value.length - 1;
        return (
          <Body key={option.id} size="lg" sx={{whiteSpace: 'pre'}}>
            {option.label}
            {isLast ? null : ', '}
          </Body>
        );
      });
    }

    if (multipleTextMode === 'chips') {
      return value.map(
        (
          option: {id: string; label: string; disabled?: boolean},
          index: number
        ) => (
          <Chip
            label={option.label}
            deleteIcon={
              <Box sx={{display: 'flex'}}>
                <Icon
                  size="medium"
                  appearance="neutral"
                  name="Actions-Close-Small"
                />
              </Box>
            }
            variant={selectedOptionVariant}
            sx={{
              '& .MuiChip-label': {
                fontSize: '14px',
                lineHeight: '18px',
              },
            }}
            {...getTagProps({index})}
            disabled={!!option.disabled || disabled}
            key={option.id}
          />
        )
      );
    }
    return null;
  };

  const handleRenderOption = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    state: AutocompleteRenderOptionState
  ) => {
    if (renderOption) {
      return renderOption(optionProps, option, state);
    }

    const optionLabel = renderOptionLabel ? (
      renderOptionLabel(option)
    ) : (
      <span>{option.label}</span>
    );
    const {selected} = state;
    const classNames = [optionProps.className, styles.option];
    if (selected) {
      classNames.push(styles.selected);
    }

    return (
      <li {...optionProps} className={classNames.join(' ')} key={option.id}>
        <div className={styles.content}>
          {multiple ? (
            <div className={styles.checkboxContainer}>
              <Checkbox
                checked={selected}
                onChange={() => {}}
                customCheckedIcon={<img src={checkboxCheckedSrc} alt="" />}
                customUncheckedIcon={<img src={checkboxUncheckedSrc} alt="" />}
              />
            </div>
          ) : null}
          {optionLabel}
        </div>
      </li>
    );
  };

  const getAutocompleteSx = () => {
    return {
      '.MuiInputBase-root': {minWidth, minHeight},
      ...sx,
    };
  };

  return (
    <div data-testid={testID}>
      <AutocompleteMui
        clearIcon={clearIcon}
        options={options}
        freeSolo
        multiple={multiple}
        loading={loading}
        sx={getAutocompleteSx()}
        classes={{popper: styles.popper}}
        forcePopupIcon={forcePopupIcon}
        componentsProps={{
          clearIndicator: {disableRipple: true},
          popupIndicator: {disableRipple: true},
        }}
        popupIcon={
          options.length ? (
            <Icon
              size="medium"
              appearance="neutral"
              name="Actions-Arrow-Down-Small"
              color={
                disabled
                  ? designSystemToken('semantic.fg.inverse-secondary')
                  : designSystemToken('semantic.fg.neutral')
              }
            />
          ) : undefined
        }
        disableCloseOnSelect={disableCloseOnSelect}
        value={selectedOptions}
        inputValue={inputValue}
        onChange={onChange}
        renderTags={handleRenderTags}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            disabled={disabled}
            className={!clearIcon ? 'smallPadding' : ''}
            placeholder={renderPlaceholder()}
            borderWidth={borderWidth}
            sx={{
              caretColor: hideInputCursor ? 'transparent' : 'inherit',
              cursor: hideInputCursor ? 'default' : 'inherit',
            }}
            inputProps={{
              ...params.inputProps,
              onBlur: (event) => {
                onBlur?.(event, params.inputProps.value);
                params.inputProps.onBlur?.(
                  event as React.FocusEvent<HTMLInputElement>
                );
              },
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onInputChange?.(event);
              params?.inputProps?.onChange?.(event);
            }}
          />
        )}
        renderOption={handleRenderOption}
        isOptionEqualToValue={isOptionEqualToValue}
        groupBy={groupBy}
        renderGroup={renderGroup}
        disabled={disabled}
        filterOptions={filterOptions}
        onKeyDown={onKeyDown}
      />
    </div>
  );
}

export default AutocompleteInput;
