import {SerializedRequiredDeliverables} from '@/contexts/NewCampaignContext';

const macroCreatorsIconSrc = '/assets/svg/icon-macro-creators.svg';
const microCreatorsIconSrc = '/assets/svg/icon-micro-creators.svg';
const midTierCreatorsIconSrc = '/assets/svg/icon-mid-tier-creators.svg';
const nanoCreatorsIconSrc = '/assets/svg/icon-nano-creators.svg';

const CURRENCY_SIGN = '$';

const CONTENT_COSTS = {
  contentCreation: {
    contentStandardPhoto: 125,
    contentStandardPhotoMin: 100,
    contentStandardPhotoMax: 200,
    contentPremiumPhoto: 275,
    contentPremiumPhotoMin: 250,
    contentPremiumPhotoMax: 350,
    contentStandardVideo: 500,
    contentStandardVideoMin: 450,
    contentStandardVideoMax: 650,
    contentPremiumVideo: 1000,
    contentPremiumVideoMin: 950,
    contentPremiumVideoMax: 1150,
  },
};

const CREATORS_COSTS_MAP = {
  nanoCreators: {
    storyPost: 100,
    storyPostMin: 50,
    storyPostMax: 250,
    feedPostPhoto: 125,
    feedPostPhotoMin: 75,
    feedPostPhotoMax: 275,
    reelPost: 150,
    reelPostMin: 100,
    reelPostMax: 300,
    tiktokVideo: 250,
    tiktokVideoMin: 200,
    tiktokVideoMax: 400,
    youtubeShortFormVideo: 250,
    youtubeShortFormVideoMin: 200,
    youtubeShortFormVideoMax: 400,
    youtubeLongFormVideo: 350,
    youtubeLongFormVideoMin: 300,
    youtubeLongFormVideoMax: 500,
    youtubeShort: 250,
    youtubeShortMin: 200,
    youtubeShortMax: 400,
    ...CONTENT_COSTS.contentCreation,
  },
  microCreators: {
    storyPost: 200,
    storyPostMin: 150,
    storyPostMax: 350,
    feedPostPhoto: 250,
    feedPostPhotoMin: 200,
    feedPostPhotoMax: 400,
    reelPost: 300,
    reelPostMin: 250,
    reelPostMax: 450,
    tiktokVideo: 550,
    tiktokVideoMin: 500,
    tiktokVideoMax: 700,
    youtubeShortFormVideo: 550,
    youtubeShortFormVideoMin: 500,
    youtubeShortFormVideoMax: 700,
    youtubeLongFormVideo: 650,
    youtubeLongFormVideoMin: 600,
    youtubeLongFormVideoMax: 800,
    youtubeShort: 550,
    youtubeShortMin: 500,
    youtubeShortMax: 700,
    ...CONTENT_COSTS.contentCreation,
  },
  midTierCreators: {
    storyPost: 400,
    storyPostMin: 350,
    storyPostMax: 650,
    feedPostPhoto: 500,
    feedPostPhotoMin: 450,
    feedPostPhotoMax: 650,
    reelPost: 600,
    reelPostMin: 550,
    reelPostMax: 750,
    tiktokVideo: 1200,
    tiktokVideoMin: 1150,
    tiktokVideoMax: 1300,
    youtubeShortFormVideo: 1200,
    youtubeShortFormVideoMin: 1150,
    youtubeShortFormVideoMax: 1300,
    youtubeLongFormVideo: 1300,
    youtubeLongFormVideoMin: 1250,
    youtubeLongFormVideoMax: 1450,
    youtubeShort: 1200,
    youtubeShortMin: 1150,
    youtubeShortMax: 1300,
    ...CONTENT_COSTS.contentCreation,
  },
  macroCreators: {
    storyPost: 700,
    storyPostMin: 650,
    storyPostMax: 850,
    feedPostPhoto: 900,
    feedPostPhotoMin: 850,
    feedPostPhotoMax: 1050,
    reelPost: 1100,
    reelPostMin: 1050,
    reelPostMax: 1250,
    tiktokVideo: 2000,
    tiktokVideoMin: 1950,
    tiktokVideoMax: 2200,
    youtubeShortFormVideo: 2000,
    youtubeShortFormVideoMin: 1950,
    youtubeShortFormVideoMax: 2200,
    youtubeLongFormVideo: 2100,
    youtubeLongFormVideoMin: 2050,
    youtubeLongFormVideoMax: 2250,
    youtubeShort: 2000,
    youtubeShortMin: 1950,
    youtubeShortMax: 2200,
    ...CONTENT_COSTS.contentCreation,
  },
  ...CONTENT_COSTS,
};

const CREATOR_COSTS_ICON = {
  nanoCreators: nanoCreatorsIconSrc,
  microCreators: microCreatorsIconSrc,
  midTierCreators: midTierCreatorsIconSrc,
  macroCreators: macroCreatorsIconSrc,
  contentCreation: null,
} as {[key: string]: string | null};

const CREATOR_COSTS_TITLES = {
  nanoCreators: 'Nano',
  microCreators: 'Micro',
  midTierCreators: 'Mid-Tier',
  macroCreators: 'Macro',
  contentCreation: 'Content Creation',
} as {[key: string]: string};

const CREATOR_COSTS_SUBTITLES = {
  nanoCreators: '1k-20k followers',
  microCreators: '20k-150k followers',
  midTierCreators: '150k-350k followers',
  macroCreators: 'Over 350k followers',
} as {[key: string]: string};

const CREATOR_FOLLOWERS_COUNT_RANGES = {
  nanoCreators: '1000-20000',
  microCreators: '20000-150000',
  midTierCreators: '150000-350000',
  macroCreators: '350000+',
} as {[key: string]: string};

function calculateCreatorCostAndTotalCreators(
  requiredDeliverables: SerializedRequiredDeliverables | any,
  budget: number,
  isContentCreation: boolean
) {
  const creatorTypes = isContentCreation
    ? Object.entries(CONTENT_COSTS)
    : Object.entries(CREATORS_COSTS_MAP);

  return creatorTypes.reduce((acc: any, [creatorType, pricing]) => {
    /* eslint-disable no-param-reassign */
    const creatorData = Object.entries(pricing).reduce(
      (creatorAcc: any, [deliverable, cost]) => {
        creatorAcc.cost =
          (creatorAcc.cost || 0) +
          cost * (requiredDeliverables[deliverable] || 0);

        const deliverableCostRangeMinKey = `${deliverable}Min`;
        const deliverableCostRangeMaxKey = `${deliverable}Max`;
        const deliverablePricing = pricing as {[key: string]: number};

        creatorAcc.costRangeMin =
          (creatorAcc.costRangeMin || 0) +
          (deliverablePricing[deliverableCostRangeMinKey] || 0) *
            (requiredDeliverables[deliverable] || 0);
        creatorAcc.costRangeMax =
          (creatorAcc.costRangeMax || 0) +
          (deliverablePricing[deliverableCostRangeMaxKey] || 0) *
            (requiredDeliverables[deliverable] || 0);
        return creatorAcc;
      },
      {}
    );
    /* eslint-enable no-param-reassign */
    const costRange = `${CURRENCY_SIGN}${creatorData.costRangeMin}-${CURRENCY_SIGN}${creatorData.costRangeMax}`;
    const totalCreators =
      creatorData.cost === 0 ? 0 : Math.floor(budget / creatorData.cost);
    acc[creatorType] = {
      value: creatorType,
      title: CREATOR_COSTS_TITLES[creatorType],
      subtitle: CREATOR_COSTS_SUBTITLES[creatorType],
      tooltip: totalCreators === 0 ? 'This option is out of your budget' : null,
      creatorFollowersCount: CREATOR_FOLLOWERS_COUNT_RANGES[creatorType],
      cost: creatorData.cost,
      costRange,
      totalCreators,
      disabled: totalCreators === 0 && budget !== 0,
      iconSrc: CREATOR_COSTS_ICON[creatorType],
    };

    return acc;
  }, {});
}

export default calculateCreatorCostAndTotalCreators;
