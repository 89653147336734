import React from 'react';
import FeaturePage from '../feature-page/FeaturePage';
import ReformattingConfiguration from './ReformattingConfiguration';
import ReformattingResults from './ReformattingResults';

function onReformat() {
  console.log('Reformatting...');
}

function ReformattingPage() {
  return (
    <FeaturePage
      LeftPanel={() => ReformattingConfiguration({onReformat})}
      RightPanel={ReformattingResults}
    />
  );
}

export default ReformattingPage;
