import React from 'react';
import {Box, Stack} from '@mui/material';
import {
  Body,
  Headline,
  designSystemToken,
} from '@lightricks/react-design-system';

export interface FeatureCardProps {
  title: string;
  description: string;
  onClick?: () => void;
}

function FeatureCard(props: FeatureCardProps) {
  const {title, description, onClick} = props;
  return (
    <Box
      display="flex"
      width="375px"
      height="290px"
      borderRadius="16px"
      border={`1px solid ${designSystemToken('semantic.bg.tertiary')}`}
      sx={{
        background: designSystemToken('semantic.bg.secondary'),
        boxShadow:
          '0px 0px 1px 0px rgba(14, 21, 38, 0.08), 0px 2px 32px 0px rgba(14, 21, 38, 0.06)',
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" gap="12px">
        <Stack direction="column" gap="8px">
          <Headline size="sm" color={designSystemToken('semantic.fg.primary')}>
            {title}
          </Headline>
          <Body size="sm" color={designSystemToken('semantic.fg.secondary')}>
            {description}
          </Body>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FeatureCard;
