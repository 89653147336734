import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ContentStudio from '@/views/content-studio';
import DubbingPage from '@/views/content-studio/components/dubbing-page/DubbingPage';
import ReformattingPage from '@/views/content-studio/components/reformatting-page';
import RephasingPage from '@/views/content-studio/components/rephrasing-page';

function ContentStudioRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ContentStudio />} />
      <Route path="/dubbing" element={<DubbingPage />} />
      <Route path="/rephrasing" element={<RephasingPage />} />
      <Route path="/reformatting" element={<ReformattingPage />} />
    </Routes>
  );
}

export default ContentStudioRoutes;
