import React, {useState} from 'react';
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import {
  Button,
  Headline,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

interface DubbingConfigurationProps {
  onDubAssets?: () => void;
}

function DubbingConfiguration({onDubAssets}: DubbingConfigurationProps) {
  const [targetLanguage, setTargetLanguage] = useState('');

  // List of commonly used languages for dubbing
  const languages = [
    {id: 'en', name: 'English'},
    {id: 'es', name: 'Spanish'},
    {id: 'fr', name: 'French'},
    {id: 'de', name: 'German'},
    {id: 'it', name: 'Italian'},
    {id: 'pt', name: 'Portuguese'},
    {id: 'ru', name: 'Russian'},
    {id: 'ja', name: 'Japanese'},
    {id: 'zh', name: 'Chinese'},
    {id: 'ko', name: 'Korean'},
    {id: 'ar', name: 'Arabic'},
    {id: 'hi', name: 'Hindi'},
  ];

  const handleLanguageChange = (event: any) => {
    setTargetLanguage(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: '24px',
        borderRadius: '8px',
        backgroundColor: designSystemToken('semantic.bg.secondary'),
        boxShadow: `0px 4px 10px ${designSystemToken(
          'semantic.shadow.default'
        )}`,
      }}
    >
      <Stack spacing={3}>
        <Headline size="sm">{translate('Dubbing')}</Headline>

        <FormControl fullWidth>
          <InputLabel id="target-language-label">
            {translate('Target Language')}
          </InputLabel>
          <Select
            labelId="target-language-label"
            id="target-language"
            value={targetLanguage}
            label={translate('Target Language')}
            onChange={handleLanguageChange}
            sx={{
              'borderRadius': '8px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: designSystemToken('semantic.bg.tertiary'),
              },
            }}
          >
            {languages.map((language) => (
              <MenuItem key={language.id} value={language.id}>
                {language.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box display="flex" justifyContent="flex-end">
          <Button
            appearance="brand"
            mode="filled"
            size="medium"
            onClick={onDubAssets}
            disabled={!targetLanguage}
          >
            {translate('Dub assets')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default DubbingConfiguration;
