import React, {useState} from 'react';
import {Box, Stack, TextField} from '@mui/material';
import {
  Button,
  Headline,
  designSystemToken,
} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

interface RephasingConfigurationProps {
  onRephrase?: () => void;
}

function RephasingConfiguration({onRephrase}: RephasingConfigurationProps) {
  const [text, setText] = useState('');

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  return (
    <Box
      sx={{
        padding: '24px',
        borderRadius: '8px',
        backgroundColor: designSystemToken('semantic.bg.secondary'),
        boxShadow: `0px 4px 10px ${designSystemToken(
          'semantic.shadow.default'
        )}`,
      }}
    >
      <Stack spacing={3}>
        <Headline size="sm">{translate('Rephrasing Configuration')}</Headline>

        <TextField
          fullWidth
          label={translate('Text to Rephrase')}
          multiline
          rows={4}
          value={text}
          onChange={handleTextChange}
          variant="outlined"
          sx={{
            'borderRadius': '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: designSystemToken('semantic.bg.tertiary'),
            },
          }}
        />

        <Box display="flex" justifyContent="flex-end">
          <Button
            appearance="brand"
            mode="filled"
            size="medium"
            onClick={onRephrase}
            disabled={!text}
          >
            {translate('Rephrase Text')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}

export default RephasingConfiguration;
