import React from 'react';
import {Box, Typography} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

interface DubbingResultsProps {
  projectId: string | null;
}

function DubbingResults({projectId}: DubbingResultsProps) {
  return (
    <Box
      sx={{
        padding: '24px',
        borderRadius: '8px',
        backgroundColor: designSystemToken('semantic.bg.secondary'),
        boxShadow: `0px 4px 10px ${designSystemToken(
          'semantic.shadow.default'
        )}`,
      }}
    >
      <Typography
        variant="body1"
        component="div"
        sx={{
          color: designSystemToken('semantic.fg.primary'),
          fontWeight: 500,
          marginBottom: '16px',
        }}
      >
        {translate('Dubbing Results')}
      </Typography>

      {projectId ? (
        <Typography variant="body2">
          {translate('Project ID')}: {projectId}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{color: designSystemToken('semantic.fg.tertiary')}}
        >
          {translate('No dubbing project created yet')}
        </Typography>
      )}
    </Box>
  );
}

export default DubbingResults;
