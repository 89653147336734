import React, {useCallback} from 'react';
import FeaturePage from '../feature-page/FeaturePage';
import RephasingConfiguration from './RephasingConfiguration';
import RephasingResults from './RephasingResults';

function onRephrase() {
  console.log('Rephrasing...');
}

function RephasingPage() {
  return (
    <FeaturePage
      LeftPanel={() => RephasingConfiguration({onRephrase})}
      RightPanel={RephasingResults}
    />
  );
}

export default RephasingPage;
