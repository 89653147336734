import React from 'react';
import {Box} from '@mui/material';
import {Headline, designSystemToken} from '@lightricks/react-design-system';
import translate from '@/utils/translate';

function ReformattingResults() {
  return (
    <Box
      sx={{
        padding: '24px',
        borderRadius: '8px',
        backgroundColor: designSystemToken('semantic.bg.secondary'),
        boxShadow: `0px 4px 10px ${designSystemToken(
          'semantic.shadow.default'
        )}`,
      }}
    >
      <Headline size="sm">{translate('Reformatting Results')}</Headline>
    </Box>
  );
}

export default ReformattingResults;
